import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    walletAddress:'',
    nfts:[],
}

const walletSlice = createSlice({
    name:'wallet',
    initialState,
    reducers:{
        setWalletAddress(state,action){
            state.walletAddress = action?.payload;
        },
        setNfts(state,action){
            state.nfts = action.payload;
        }
    }
});

export const { setWalletAddress, setNfts} = walletSlice.actions
export default walletSlice.reducer