import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { configEnv } from "./configEnv";
import { store } from "./store/store";
import { setNfts, setWalletAddress } from "./store/walletSlice";
import { nftMinterABI, nftPackABI, usdcABI } from "./contracts";

export let web3 = new Web3(configEnv?.rpc);

export const connectWallet = async () => {
  try {
    const state = store.getState();
    const address = state?.wallet?.walletAddress;
    if (!address) {
      console.log("Connecting to wallet");
      // /*
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            bridge: "https://bridge.walletconnect.org",
            chainId: configEnv.rpc, //137 for mainnet
            // network: configEnv.AVAX_NETWORK_ID, //matic for mainnet
            rpc: {
              // 43113: configEnv.AVAX_RPC,
              97: configEnv.rpc,
            },
          },
        },
      };

      console.log("Provider is", providerOptions);

      const web3Modal = new Web3Modal({
        // network: configEnv.AVAX_NETWORK_ID,
        cacheProvider: false, // optional
        providerOptions, // required
        disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
      });

      console.log("Web3Modal instance is", web3Modal);
      const provider = await web3Modal.connect();

      provider.on("accountsChanged", async (accounts) => {
        console.log("Accounts", accounts);
        store.dispatch(setWalletAddress(accounts[0]));
        getUserNFTs(accounts[0]);
        // await getUserDeposits();
      });

      web3 = new Web3(provider);

      console.log("Web3 instance is", web3);

      const chainid = await web3.eth.getChainId();

      console.log(chainid);
      if (chainid !== configEnv.chainId) {
        alert(`Please connect to ${configEnv.networkName}`);
        // toast.error(`Please connect to ${configEnv.networkName}`);
        return;
      }
      const accounts = await web3.eth.getAccounts();
      console.log("Acount is", accounts[0]);

      store.dispatch(setWalletAddress(accounts[0]));
      getUserNFTs(accounts[0]);
      // await getUserDeposits();
    } else {
      console.log("Already connected");
    }
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const getMinterContract = () => {
  return new web3.eth.Contract(nftMinterABI, configEnv?.nftMinterContract);
};

export const getPackContract = () => {
  return new web3.eth.Contract(nftPackABI, configEnv?.nftPackAddress);
};

export const getUSDCContract = () => {
  return new web3.eth.Contract(usdcABI, configEnv?.USDCContract);
};

export const approveAmount = async (spender, amount) => {
  try {
    const state = store.getState();
    const address = state?.wallet?.walletAddress;
    if (address) {
      const contract = getUSDCContract();

      const trx = await contract.methods.approve(spender, amount).send({
        from: address,
      });
      console.log("aprove trx", trx);

      return trx;
    }
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const mintMysteryBox1 = async () => {
  try {
    const state = store.getState();
    const address = state?.wallet?.walletAddress;

    if (address) {
      const minterContract = getMinterContract();

      const price = await minterContract.methods.mysteryBox1Price().call();

      await approveAmount(configEnv?.nftMinterContract, price);

      const trx = await minterContract.methods.mintMysteryBox1().send({
        from: address,
      });

      console.log("trx", trx);
    }
  } catch (err) {
    console.log(err);
  }
};

export const mintMysteryBox2 = async () => {
  try {
    const state = store.getState();
    const address = state?.wallet?.walletAddress;

    if (address) {
      const minterContract = getMinterContract();

      const price = await minterContract.methods.mysteryBox2Price().call();

      await approveAmount(configEnv?.nftMinterContract, price);

      const trx = await minterContract.methods.mintMysteryBox2().send({
        from: address,
      });

      console.log("trx", trx);
    }
  } catch (err) {
    console.log(err);
  }
};

export const mintMysteryBoxPackage1 = async () => {
  try {
    const state = store.getState();
    const address = state?.wallet?.walletAddress;

    if (address) {
      const minterContract = getMinterContract();

      const price = await minterContract.methods
        .mysteryBoxPackage1Price()
        .call();

      await approveAmount(configEnv?.nftMinterContract, price);

      const trx = await minterContract.methods.mintMysteryBoxPackage1().send({
        from: address,
      });

      console.log("trx", trx);
    }
  } catch (err) {
    console.log(err);
  }
};

export const mintMysteryBoxPackage2 = async () => {
  try {
    const state = store.getState();
    const address = state?.wallet?.walletAddress;

    if (address) {
      const minterContract = getMinterContract();

      const price = await minterContract.methods
        .mysteryBoxPackage2Price()
        .call();

      await approveAmount(configEnv?.nftMinterContract, price);

      const trx = await minterContract.methods.mintMysteryBoxPackage2().send({
        from: address,
      });

      console.log("trx", trx);
    }
  } catch (err) {
    console.log(err);
  }
};

export const getUserNFTs = async (userAddress) => {
  try {
    const packNft = getPackContract();

    const ids = [5, 6];
    // const nft
    const nfts = [];
    for await (let id of ids) {
      const balance = await packNft.methods.balanceOf(userAddress, id).call();

      const data = {
        id: id,
        balance: parseInt(balance),
      };
      nfts.push(data);
    }

    store.dispatch(setNfts(nfts));
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const openPack = async(id,amount)=>{
	try{
		const state = store.getState();
		const address = state?.wallet?.walletAddress;
	
		if (address) {
		const packContract = getPackContract();

		const trx = await packContract.methods.openPack(id,amount).send({
			from:address,
		});

		console.log('trx',trx)
	}
	}
	catch(err){
		console.log(err);
		throw new Error(err?.message);
	}
}