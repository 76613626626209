import "./App.css";
import { useSelector } from "react-redux";
import {
  connectWallet,
  mintMysteryBox1,
  mintMysteryBox2,
  mintMysteryBoxPackage1,
  mintMysteryBoxPackage2,
  openPack,
} from "./web3-service";

function App() {
  const { walletAddress, nfts } = useSelector((state) => state?.wallet);

  return (
    <div className="App">
      <header className="App-header">
        <button
          onClick={() => {
            if (!walletAddress) {
              connectWallet();
            }
          }}
        >
          {walletAddress
            ? walletAddress?.replace(walletAddress?.slice(5, 38), "****")
            : "Connect Wallet"}
        </button>

        {walletAddress && (
          <>
            <div>
              <button onClick={() => mintMysteryBox1()}>
                Mint Mystery Box 1
              </button>
              <br />
              <button onClick={() => mintMysteryBox2()}>
                Mint Mystery Box 2
              </button>
              <br />
              <button onClick={() => mintMysteryBoxPackage1()}>
                Mint Mystery Box Package 1
              </button>
              <br />
              <button onClick={() => mintMysteryBoxPackage2()}>
                Mint Mystery Box Package
              </button>
              <br />
            </div>
            <div>
              {nfts.map((item, index) => {
                if (item?.balance) {
                  return <div>
                    <p>Pack Box {index+1} {`(${item?.balance})`}</p>
                    <button onClick={()=>openPack(item?.id,1)}>Open Pack</button>
                  </div>;
                }
              })}
            </div>
          </>
        )}
      </header>
    </div>
  );
}

export default App;
